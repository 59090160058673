// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3P1Z8-5wGU4hMPqakSxm3g ._1eBFE3Ail80_oavE0gWan9 {\n  border-radius: 10px;\n  background: rgba(128, 128, 128, 0.08);\n  padding: 50px 30px;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._1eBFE3Ail80_oavE0gWan9 > *:not(:last-child) {\n  margin-right: 20px;\n  margin-bottom: 20px;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._2Cnv_R0wbWLZHTeyxcUpre {\n  display: none;\n  color: white;\n  border-radius: 0 0 10px 10px;\n  overflow: hidden;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._2Cnv_R0wbWLZHTeyxcUpre code {\n  overflow: auto;\n  white-space: pre;\n  width: 100%;\n  display: block;\n  padding: 20px !important;\n  border-radius: 0 !important;\n  font-size: 16px;\n  background: var(--code-background-color);\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._3qFbV-1DjPRbdPZIirnIFA {\n  display: flex;\n  justify-content: flex-end;\n  padding: 10px;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._3qFbV-1DjPRbdPZIirnIFA button {\n  all: unset;\n  margin-left: 20px;\n  cursor: pointer;\n  font-size: 12px;\n  opacity: 0.4;\n  transition: opacity 0.2s ease;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._3qFbV-1DjPRbdPZIirnIFA button:hover {\n  opacity: 0.8;\n}\n._3P1Z8-5wGU4hMPqakSxm3g ._3qFbV-1DjPRbdPZIirnIFA button:disabled {\n  cursor: default;\n  pointer-events: none;\n}\n._3P1Z8-5wGU4hMPqakSxm3g._1bwD9jTI0TZqo1RRqK6O3N ._1eBFE3Ail80_oavE0gWan9 {\n  border-radius: 10px 10px 0 0;\n}\n._3P1Z8-5wGU4hMPqakSxm3g._1bwD9jTI0TZqo1RRqK6O3N ._2Cnv_R0wbWLZHTeyxcUpre {\n  display: block;\n}\n\n@media (max-width: 900px) {\n  ._3P1Z8-5wGU4hMPqakSxm3g ._1eBFE3Ail80_oavE0gWan9 {\n    padding: 30px 20px;\n  }\n  ._3P1Z8-5wGU4hMPqakSxm3g ._2Cnv_R0wbWLZHTeyxcUpre code {\n    font-size: 12px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"demo": "_3P1Z8-5wGU4hMPqakSxm3g",
	"preview": "_1eBFE3Ail80_oavE0gWan9",
	"source": "_2Cnv_R0wbWLZHTeyxcUpre",
	"actions": "_3qFbV-1DjPRbdPZIirnIFA",
	"show": "_1bwD9jTI0TZqo1RRqK6O3N"
};
module.exports = exports;
