// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._28V5TPsbsqgIbn9ZMszuJl {\n  padding: 40px 140px 60px 60px;\n  border-radius: 35px;\n  background-color: var(--panel-color);\n  font-size: 18px;\n}\n._28V5TPsbsqgIbn9ZMszuJl:not(:last-child) {\n  margin-bottom: 20px;\n}\n._28V5TPsbsqgIbn9ZMszuJl h1 {\n  margin-bottom: 20px;\n}\n._28V5TPsbsqgIbn9ZMszuJl h2 {\n  margin-bottom: 20px;\n}\n._28V5TPsbsqgIbn9ZMszuJl h3 {\n  margin: 20px 0;\n}\n._28V5TPsbsqgIbn9ZMszuJl p {\n  margin-bottom: 20px;\n  line-height: 28px;\n  color: var(--grayscale-level2);\n}\n._28V5TPsbsqgIbn9ZMszuJl hr {\n  margin-bottom: 30px;\n  border: none;\n  height: 1px;\n  background: var(--grayscale-level1);\n}\n._28V5TPsbsqgIbn9ZMszuJl code {\n  font-weight: bold;\n}\n._28V5TPsbsqgIbn9ZMszuJl table {\n  margin-bottom: 40px;\n}\n\n@media (max-width: 900px) {\n  ._28V5TPsbsqgIbn9ZMszuJl {\n    padding: 20px;\n    border-radius: 0;\n  }\n  ._28V5TPsbsqgIbn9ZMszuJl h2 {\n    margin-bottom: 10px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"panel": "_28V5TPsbsqgIbn9ZMszuJl"
};
module.exports = exports;
