// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2vrcKu4faQ42x1vhG7e-cl section:not(:last-child) {\n  margin-bottom: 40px;\n}\n._2vrcKu4faQ42x1vhG7e-cl section h2 {\n  margin-bottom: 10px;\n}\n._2vrcKu4faQ42x1vhG7e-cl section p {\n  margin-bottom: 8px;\n}", ""]);
// Exports
exports.locals = {
	"homePage": "_2vrcKu4faQ42x1vhG7e-cl"
};
module.exports = exports;
