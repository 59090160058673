// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2GzGP5byDS5xHjbaXxtGPf {\n  padding: 13px 30px;\n  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);\n  background-color: var(--theme-selector-color);\n  border-radius: 20px;\n  width: 215px;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li {\n  font-size: 16px;\n  font-weight: 600;\n  padding: 7px 0;\n  opacity: 0.2;\n  position: relative;\n  cursor: pointer;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li:after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  margin: auto;\n  height: 14px;\n  width: 14px;\n  border-radius: 7px;\n  opacity: 0;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li.AVwE8_dzUPAEVxdW6Ydz9:after {\n  background: var(--magnetar);\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li._1MWNl2X5GepvepLD5wemnx:after {\n  background: var(--ressonance);\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li._3tnGVXkTrUTt1IVbnEi3Dc:after {\n  background: var(--event-horizon);\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li._3PqW1HgYnscTd77pAWEx_o:after {\n  background: var(--neutrino);\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li:hover {\n  opacity: 1;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li:hover:after {\n  opacity: 1;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li.JS6No2jUZfj2Irk7hqq9Z {\n  opacity: 1;\n}\n._2GzGP5byDS5xHjbaXxtGPf ul li.JS6No2jUZfj2Irk7hqq9Z:after {\n  opacity: 1;\n}", ""]);
// Exports
exports.locals = {
	"themeSelector": "_2GzGP5byDS5xHjbaXxtGPf",
	"magnetar": "AVwE8_dzUPAEVxdW6Ydz9",
	"ressonance": "_1MWNl2X5GepvepLD5wemnx",
	"eventHorizon": "_3tnGVXkTrUTt1IVbnEi3Dc",
	"neutrino": "_3PqW1HgYnscTd77pAWEx_o",
	"active": "JS6No2jUZfj2Irk7hqq9Z"
};
module.exports = exports;
