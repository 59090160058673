// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ttzgED3VoUhxyGWr_IAUK table,\n._1ttzgED3VoUhxyGWr_IAUK th,\n._1ttzgED3VoUhxyGWr_IAUK td {\n  border-collapse: collapse;\n  text-align: left;\n  font-size: 14px;\n}\n._1ttzgED3VoUhxyGWr_IAUK table {\n  width: 100%;\n}\n._1ttzgED3VoUhxyGWr_IAUK table th {\n  border-bottom: 1px solid rgba(128, 128, 128, 0.5);\n}\n._1ttzgED3VoUhxyGWr_IAUK table th,\n._1ttzgED3VoUhxyGWr_IAUK table td {\n  padding: 15px;\n  opacity: 0.6;\n}\n._1ttzgED3VoUhxyGWr_IAUK table td {\n  font-family: monospace;\n}\n._1ttzgED3VoUhxyGWr_IAUK table tbody tr:nth-child(odd) {\n  background: rgba(128, 128, 128, 0.04);\n}\n\n@media (max-width: 900px) {\n  ._1ttzgED3VoUhxyGWr_IAUK {\n    width: 100%;\n    overflow: auto;\n  }\n  ._1ttzgED3VoUhxyGWr_IAUK td {\n    font-size: 12px;\n    white-space: pre;\n  }\n  ._1ttzgED3VoUhxyGWr_IAUK::-webkit-scrollbar {\n    display: none;\n  }\n}", ""]);
// Exports
exports.locals = {
	"table": "_1ttzgED3VoUhxyGWr_IAUK"
};
module.exports = exports;
