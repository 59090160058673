// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MxQPPIR0RyGdNqgSE_IBJ code {\n  overflow: auto;\n  width: 100%;\n  display: block;\n  padding: 20px !important;\n  font-size: 14px;\n  white-space: pre !important;\n  border-radius: 10px !important;\n  background: var(--code-background-color) !important;\n}\n.MxQPPIR0RyGdNqgSE_IBJ ._19uC_2LX_ptxJNaogwsemN {\n  display: flex;\n  justify-content: flex-end;\n  font-size: 12px;\n  opacity: 0;\n  transition: opacity 0.2s ease;\n  padding: 10px;\n}\n.MxQPPIR0RyGdNqgSE_IBJ ._19uC_2LX_ptxJNaogwsemN._1Gpzaf41_ulqKmXzbXrWTh {\n  opacity: 0.4;\n}\n\n@media (max-width: 900px) {\n  .MxQPPIR0RyGdNqgSE_IBJ code {\n    font-size: 12px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"code": "MxQPPIR0RyGdNqgSE_IBJ",
	"subtext": "_19uC_2LX_ptxJNaogwsemN",
	"visible": "_1Gpzaf41_ulqKmXzbXrWTh"
};
module.exports = exports;
