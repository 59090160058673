// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qWz3D3zaT7qvrFNcLUiww {\n  background: var(--nav-color);\n  border-radius: 35px;\n  padding: 30px 0;\n  overflow: hidden;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  font-size: 22px;\n  font-weight: 600;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul a {\n  color: inherit;\n  text-decoration: none;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul._2hz05mtr2-ZvPHsPd92RjY li {\n  position: relative;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul._2hz05mtr2-ZvPHsPd92RjY li a {\n  padding: 10px 42px;\n  position: relative;\n  display: block;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul.Oh1ISfYgPE7KTfRINTgz3 {\n  overflow: hidden;\n  max-height: 0;\n  transition: all 0.2s ease-out;\n  opacity: 0;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul.Oh1ISfYgPE7KTfRINTgz3 li {\n  position: relative;\n  color: var(--grayscale-level3);\n}\n._1qWz3D3zaT7qvrFNcLUiww ul.Oh1ISfYgPE7KTfRINTgz3 li a {\n  padding: 10px 62px;\n  font-size: 18px;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul.Oh1ISfYgPE7KTfRINTgz3 li:hover {\n  color: inherit;\n}\n._1qWz3D3zaT7qvrFNcLUiww ul.Oh1ISfYgPE7KTfRINTgz3.atdPKEpBof02lcHMbY6-g {\n  display: block;\n  max-height: 330px;\n  opacity: 1;\n}\n\n._2iwgVHRQLAJRa6YMHyGNqC:before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  height: 36px;\n  margin: auto;\n  background: var(--primary-theme-color);\n  width: 3px;\n  box-shadow: 0 0 70px 30px rgba(var(--primary-theme-color-rgb), 0.1);\n}\n\n@media (max-width: 900px) {\n  ._1qWz3D3zaT7qvrFNcLUiww ul {\n    font-size: 20px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"sideMenu": "_1qWz3D3zaT7qvrFNcLUiww",
	"primaryLevel": "_2hz05mtr2-ZvPHsPd92RjY",
	"secondaryLevel": "Oh1ISfYgPE7KTfRINTgz3",
	"menuOpen": "atdPKEpBof02lcHMbY6-g",
	"active": "_2iwgVHRQLAJRa6YMHyGNqC"
};
module.exports = exports;
