// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3VPbZdWSUQKb3SjhvxHn8V {\n  background-color: var(--nav-color);\n  border-radius: 35px;\n  width: 70px;\n  height: 70px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transform: rotate(180deg);\n  transition: transform 0.4s ease-out;\n}\n._3VPbZdWSUQKb3SjhvxHn8V.sruCB-sOjU3mKD3wHId4- {\n  transform: rotate(0deg);\n}\n._3VPbZdWSUQKb3SjhvxHn8V.sruCB-sOjU3mKD3wHId4- svg path {\n  fill: var(--white);\n}\n\n@media (max-width: 900px) {\n  ._3VPbZdWSUQKb3SjhvxHn8V {\n    padding: 0;\n    width: auto;\n    height: auto;\n    background-color: transparent;\n  }\n  ._3VPbZdWSUQKb3SjhvxHn8V svg {\n    width: 20px;\n    height: 20px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"schemeToggle": "_3VPbZdWSUQKb3SjhvxHn8V",
	"light": "sruCB-sOjU3mKD3wHId4-"
};
module.exports = exports;
